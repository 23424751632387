import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { CategoriesTabDispatchProps, CategoriesTabStateProps } from './types';
import CategoriesTabC from '../../components/CategoriesTab';
import { AppState } from '../../types';
import {
  fetchEventCategories as fetchEventCategoriesAC,
  resetEventCategoriesMessages as resetEventCategoriesMessagesAC,
  resetEventCategories as resetEventCategoriesAC,
  fetchThemes as fetchThemesAC,
  resetThemesMessages as resetThemesMessagesAC,
  resetThemes as resetThemesAC,
} from '../../actions';
import {
  getEventCategoriesList,
  getEventCategoriesLoading,
  getEventCategoriesError,
  getThemesList,
  getThemesLoading,
  getThemesError,
  getTopics,
} from '../../selectors';
import { CategoryRecord } from '../../models';

interface CategoriesTabProps
  extends CategoriesTabDispatchProps,
    CategoriesTabStateProps {}

const CategoriesTab: FunctionComponent<CategoriesTabProps> = ({
  fetchEventCategories,
  categories,

  fetchThemes,
  themes,

  topics,
}: CategoriesTabProps) => {
  useEffect(() => {
    fetchEventCategories();
    fetchThemes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newCategories = useMemo(() => {
    if (!categories) return List<CategoryRecord>();
    const foundCategory = categories.find(item => item.id === 16);
    if (!foundCategory) return categories;
    const preparedCategories = categories.filter(item => item.id !== 16);
    return List([foundCategory, ...preparedCategories]);
  }, [categories]);

  return (
    <CategoriesTabC
      categories={newCategories}
      themes={themes}
      topics={topics}
    />
  );
};

/**
* CategoriesTab map state to props
*
* @param {Dispatch} dispatch
@returns {CategoriesTabDispatchProps}
*/
const mapDispatchToProps = (dispatch: Dispatch): CategoriesTabDispatchProps => {
  return bindActionCreators(
    {
      fetchEventCategories: fetchEventCategoriesAC,
      resetEventCategoriesMessages: resetEventCategoriesMessagesAC,
      resetEventCategories: resetEventCategoriesAC,
      fetchThemes: fetchThemesAC,
      resetThemesMessages: resetThemesMessagesAC,
      resetThemes: resetThemesAC,
    },
    dispatch,
  );
};

/**
 * CategoriesTab map state to props
 *
 * @param {AppState} appState
 * @returns {CategoriesTabStateProps}
 */
const mapStateToProps = (appState: AppState): CategoriesTabStateProps => {
  return {
    categories: getEventCategoriesList(appState),
    loading: getEventCategoriesLoading(appState) || getThemesLoading(appState),
    error: getEventCategoriesError(appState) || getThemesError(appState),
    themes: getThemesList(appState),
    topics: getTopics(appState),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesTab);
