import React, { FunctionComponent, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MawazineBanner from '../../images/banner/mawazine-banner.jpeg';

import { EventsGrid, PastEventsGrid } from '../../containers';
import { CategoryRecord } from '../../models';
import BannerSlider from '../BannerSlider';
import AppMeta from '../common/AppMeta';

export interface CategoryPageProps {
  category?: CategoryRecord;
}

const Sport: FunctionComponent<CategoryPageProps> = ({
  category,
}: CategoryPageProps) => {
  useEffect(() => {
    window.fbq('track', 'ViewCategory', {
      content_category: category.get('title'),
    });
  }, [category]);

  return (
    <div>
      <AppMeta
        title={category.get('metaTitle')}
        description={category.get('metaDescription')}
        keywords={category.get('metaKeywords')}
      />

      {/* <BannerSlider
        banner={category.get('id') === 15 ? MawazineBanner : undefined}
        items={category.get('sliderItems')}
      /> */}
      {!!category && <EventsGrid categoryId={category.get('id')} />}
      {!!category && <PastEventsGrid categoryId={category.get('id')} />}
    </div>
  );
};

export default Sport;
